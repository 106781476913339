<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "containers.add-level-to-container.add-level-to-container"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('containers', 'add-level-to-container', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul class="clebex-item-section pill" v-if="levels && levels.length">
      <li
        class="clebex-item-section-item"
        v-for="level in levels"
        :key="level.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedLevel(level)"
        >
          <span class="label">
            <span class="highlight">{{
              level.all_parents && level.all_parents.length
                ? `${level.name},`
                : level.name
            }}</span>
            {{ displayParentNames(level.all_parents) }}</span
          >
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="
                selectedLevels &&
                  selectedLevels.filter(item => item.id === level.id).length
              "
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "ContainersAddLevel",
  mixins: [paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  created() {
    this.getLevelsList();
  },
  computed: {
    ...mapState("containers", [
      "listOfLevels",
      "selectedLevels",
      "levelsInContainer"
    ]),
    levels() {
      const query = this.searchQuery;
      if (this.listOfLevels && this.listOfLevels.length) {
        if (query && query.length > 1) {
          return this.listOfLevels.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.listOfLevels;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("containers", ["getLevelsList", "setSelectedLevel"]),
    displayParentNames(parents) {
      const filteredParents = parents.filter(item => item.name);
      return filteredParents.map(parent => parent.name).join(", ");
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
